.timelineImage {
    width: 250px;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.timelineText {
    text-align: center;
}

.vertical-timeline-element-title {
    text-align: center;
}