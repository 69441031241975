.NavBar {
  background-color: black;
  font-size: 20px;
  color: white;
  height: 70px;
}

.teslaLogo {
  width: 200px;
}

.teslaphoto {
  width: 75%;
}

.top-buffer { 
  margin-top:20px; 
}

.border-top { 
  border-top: 1px solid #e5e5e5; 
}

.border-bottom { 
  border-bottom: 1px solid #e5e5e5; 
}

/* Styles for wrapping the search box */

.main {
  width: 50%;
  margin: 50px auto;
}

/* Bootstrap 4 text input with search icon */

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
  