#articles-search-results {
    width: 75%;
    margin: 0px 50px;
}

#articles-search-results > tbody > tr > td {
    /** vertical-align: middle; */
}

#articles-search-results img {
    width: 200px;
}

.article-search-count {
    padding: 25px;
}

.search-results .row {
    padding: 50px;
}

.search-results .row-height {
    text-align: center;
    padding-top: 25px;
}

.search-results .search-facet {
    background-color: #e3e3e3;
    border-radius: 5px;
    text-align: center;
    margin-top: 25px;
}

.search-results .col-md-6 {
    background-color: #f5f3f3;
}

.search-results .col-md-4 {
    border-radius: 15px 0 0 15px;
}

.search-results .col-md-2 {
    border-radius: 0 15px 15px 0;
}

.search-results .article-text {
    text-align: left;
}

.search-results mark {
    background-color: yellow;
}